import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/index"
import Seo from "../components/seo"
import Logo from "../assets/logo.svg"
import StaticLogo from "../assets/flux-logo.svg"



import '../styles/community.scss'

export const query = graphql`
  query communityQuery {
    allAirtableCommunity {
        edges {
          node {
            data {
              Year
              Title
              Link_to_Portfolio
              Last_Name
              First_Name
              Approval_Status
              Profile_Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(height: 400, quality: 80, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
  }
`

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}
  

const CommunityCard = (props) => {

    return(
        <a className="communityCard" href={props.link} target="_blank">
            <div>
                <GatsbyImage className="profileImage" image={props.photo} alt={props.firstName}/>
                <div className="profileText">
                    <h4>{props.firstName} {props.lastName}</h4>
                    <p>{props.year} | {props.title} </p>
                </div>
            </div>
        </a>
    )
}

const CommunityPage = (props) => {
    const {data, errors, activeFilters} = props

    let communityArray = data.allAirtableCommunity.edges

    useEffect(() => {
      shuffle(communityArray);
    }, [])
    
    console.log(communityArray)

  return(
    <>
      <Seo title="Community" />
      <div className="community">
        <div className="communityProfiles">
        {
          communityArray.map(person => {
            // console.log(person.node.data)
            if(!person.node.data.Approval_Status) {
                return;
            }
            if(activeFilters.length == 0) {
              return(
                <CommunityCard
                      key={person.node.data.First_Name} 
                      firstName={person.node.data.First_Name} 
                      lastName ={person.node.data.Last_Name} 
                      year ={person.node.data.Year} 
                      title={person.node.data.Title} 
                      link={person.node.data.Link_to_Portfolio}
                      photo={person.node.data.Profile_Image.localFiles[0].childImageSharp.gatsbyImageData}/> 
              )
            }

            if(activeFilters.includes(person.node.data.Year)) {
              return(
                <CommunityCard
                      key={person.node.data.First_Name} 
                      firstName={person.node.data.First_Name} 
                      lastName ={person.node.data.Last_Name} 
                      year ={person.node.data.Year} 
                      title={person.node.data.Title} 
                      link={person.node.data.Link_to_Portfolio}
                      photo={person.node.data.Profile_Image.localFiles[0].childImageSharp.gatsbyImageData} /> 
              )
            }

            return;
          })
        }
        </div>
      </div>
    </>
  )
}

export default CommunityPage
